import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { reportApiError } from '../errors';
import { adrollApi } from '../services/adroll';
import FormSpinner from './FormSpinner';

const REFRESH_RATE_MS = 60 * 60 * 1000; // 1 hour

interface IProps {
    children: React.ReactNode;
}

const UNAUTH_PAGES = ['signin', 'signup', 'saml'];

export const isUnauthenticatedPage = (pathname: string) =>
    UNAUTH_PAGES.some((p) => pathname.includes(p));

const StatusWrapper = ({ children }: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [redirectSpinner, setRedirectSpinner] = useState(false);

    useEffect(() => {
        const callStatus = async () => {
            try {
                const res = await adrollApi.get('entryhall/status');
                switch (res.type) {
                    case 'internal_redirect':
                        if (
                            `/${res.action}` !== location.pathname &&
                            // The internal redirect is prevented if the backend wants to redirect to signin
                            // but the user is on an unauthenticated page.
                            !(
                                res.action === 'signin' &&
                                isUnauthenticatedPage(location.pathname)
                            )
                        ) {
                            navigate(`../${res.action}`);
                        }
                        break;
                    case 'external_redirect':
                        setRedirectSpinner(true);
                        window.location.href = res.action;
                        break;
                    default:
                        throw new Error(
                            `Unknown redirect type "${res.type}" from status API`
                        );
                }
            } catch (error) {
                reportApiError(error);
            }
        };

        callStatus();

        const intervalId = setInterval(callStatus, REFRESH_RATE_MS);
        return () => clearInterval(intervalId);
    }, []);

    return <>{redirectSpinner ? <FormSpinner /> : children}</>;
};

export default StatusWrapper;
