import { Map } from 'immutable';
import { createContext } from 'react';

import { SignUpActions } from '../../actions/sign-up/SignUpActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { IField } from '../../typings';
import { ImmutableMap } from '../../typings';
import {
    ExtraFields,
    Fields,
    INITIAL_FIELD_STATE,
} from '../../utils/sign-up/fields';

interface IFirstNameState extends IField<string> {}
interface ILastNameState extends IField<string> {}
interface IEmailState extends IField<string> {}
interface IUrlState extends IField<string> {}
interface ICompanyMarketState extends IField<string> {}
interface ICountryCodeState extends IField<string> {}
interface IRegionState extends IField<string> {}
interface IPasswordState extends IField<string> {}

export interface ISignUpFields extends Fields {
    firstName: IFirstNameState;
    lastName: ILastNameState;
    email: IEmailState;
    url: IUrlState;
    companyMarket: ICompanyMarketState;
    countryCode: ICountryCodeState;
    region: IRegionState;
    password: IPasswordState;
}

export interface ISignUpState {
    fields: ISignUpFields;
    extraFields: ExtraFields;
    googleIdToken: string;
    reCaptcha: {
        expiresAt: Date;
        refreshesCount: number;
        token: string;
    };
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
        redirect: string;
        to: string;
    };
}

export type ImmutableSignUpState = ImmutableMap<ISignUpState>;

interface ISignUpContextValue {
    state: ImmutableSignUpState;
    dispatch: React.Dispatch<SignUpActions>;
}

export const initialState: ISignUpState = {
    fields: {
        firstName: INITIAL_FIELD_STATE,
        lastName: INITIAL_FIELD_STATE,
        email: INITIAL_FIELD_STATE,
        url: INITIAL_FIELD_STATE,
        companyMarket: INITIAL_FIELD_STATE,
        countryCode: INITIAL_FIELD_STATE,
        region: INITIAL_FIELD_STATE,
        password: INITIAL_FIELD_STATE,
    },
    extraFields: {},
    googleIdToken: '',
    reCaptcha: {
        expiresAt: new Date(),
        refreshesCount: 0,
        token: '',
    },
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
        redirect: null,
        to: null,
    },
};

export const INITIAL_SIGN_UP_FORM_STATE = Map(initialState);

const defaultValue: ISignUpContextValue = {
    state: INITIAL_SIGN_UP_FORM_STATE,
    dispatch: () => null,
};

const SignUpContext = createContext<ISignUpContextValue>(defaultValue);

export default SignUpContext;
