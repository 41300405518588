import {
    BUSINESS_UNIT_ADROLL,
    BUSINESS_UNIT_ROLLWORKS, // @ts-ignore - no types for ar-style-base
} from '@nextroll/ar-style-base';

export enum API_STATUSES {
    IDLE = 'IDLE',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    IN_PROGRESS = 'IN_PROGRESS',
}

// BU-related constants.
export type SupportedBUs = BUSINESS_UNIT_ADROLL | BUSINESS_UNIT_ROLLWORKS;

export const SUPPORT_EMAIL_ADDRESS = {
    [BUSINESS_UNIT_ADROLL]: 'support@adroll.com',
    [BUSINESS_UNIT_ROLLWORKS]: 'support@rollworks.com',
};

export const MARKETING_WEBSITE_URLS = {
    [BUSINESS_UNIT_ADROLL]: 'https://www.adroll.com/',
    [BUSINESS_UNIT_ROLLWORKS]: 'http://rollworks.com/',
};

export const BUSINESS_UNIT_NAMES = {
    [BUSINESS_UNIT_ADROLL]: 'AdRoll',
    [BUSINESS_UNIT_ROLLWORKS]: 'RollWorks',
};

export const NORTH_AMERICAN_COUNTRIES = ['US', 'CA'];

export const PHONE_NUMBER_PLACEHOLDER = '415-555-5555';

export const EMAIL_METHOD = 'tfa_email_enabled';
export const SMS_METHOD = 'tfa_sms_enabled';
export const GAPP_METHOD = 'tfa_google_authenticator_enabled';

export enum RememberMyDeviceStatuses {
    expired = 'expired',
    invalid = 'invalid',
    unknown = 'unknown',
}
