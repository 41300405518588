import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    setFieldFactory,
    setShowFieldErrorFactory,
} from '../../../actions/sign-up/SignUpActions';
import SignUpContext from '../../../contexts/sign-up/SignUpContext';
import { required } from '../../../utils/sign-up/fields';
import { isNameValid } from '../../../utils/validators';

export const validate = required((value: string) => {
    if (!isNameValid(value)) {
        return i18n.gettext('Please enter a valid first name.');
    }
    return null;
});

const FirstName = () => {
    const { state, dispatch } = useContext(SignUpContext);

    const field = state.get('fields').firstName;
    const setField = setFieldFactory(dispatch, 'firstName');
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const err = validate(value);
        setField(value, err === null, err);
    };
    const setShowFieldError = setShowFieldErrorFactory(dispatch, 'firstName');

    return (
        <div className='entryhall-input-field-wrapper'>
            <InputGroup
                i18n={i18n}
                classNames='entryhall-input-first-name'
                inputProps={{
                    autoComplete: 'given-name',
                    name: 'first_name',
                    placeholder: i18n.gettext('First Name'),
                    type: 'text',
                    value: field.value,
                    onChange,
                    onFocus: () => setShowFieldError(false),
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                        onChange(e); // This is to show the error message when the user does focus out w/ having made any changes
                        setShowFieldError(true);
                    },
                }}
                validationState={
                    !field.valid && field.showError
                        ? InputGroup.VALIDATION_ERROR
                        : undefined
                }
                help={field.showError ? field.error : undefined}
            />
        </div>
    );
};

export default FirstName;
